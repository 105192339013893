<template>
    <section :id="cmsBlock.anchorTag" class="component-container white">
        <div class="team-members">
        <TeamMember
            :teamMember="entry.teamMember"
            v-for="entry in cmsBlock.teamMembers"
            :key="entry.id"
            :teamMemberStyle="entry.style"
            :placeholderImage="placeholderImage"
            class="dark"
        />
        <div v-if="cmsBlock.placeholders.data" v-for="placeholder in cmsBlock.placeholders.data" class="placeholder">
            <picture>
                <template v-for="[format, set] of Object.entries(srcsets)">
                    <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 100vw, 75vw" :type="'image/' + format.slice(1)" >
                </template>
                <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]" :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 100vw, 75vw" width="100%">
            </picture>
        </div>
    </div>
    </section>
</template>

<script>
    import TeamMember from '@/components/PageBuilder/Elements/TeamMember'
    import { globalOptionQuery } from '@/queries'

    export default {
        name: 'TeamMembers',

        props: [
            'cmsBlock'
        ],

        components: {
            TeamMember
        },

        data() {
            return {
                open: false,
                defaultTeamMemberImage: null
            }
        },

        apollo: {
            defaultTeamMemberImage: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'default-team-member-image'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            },
        },

        computed: {
            placeholderImage() {
                if (this.defaultTeamMemberImage?.attributes?.image){
                    return this.localize(this.defaultTeamMemberImage.attributes.image)
                }
            },

            srcsets(){
                return this.getImageSrcsets(this.placeholderImage.data.attributes)
            },

            hasWebp(){
                return this.srcsets['.webp'] ? true : false
            },

            originalFormat(){
                for (const[format, _] of Object.entries(this.srcsets)) {
                    if (format !== '.webp') {
                        return format
                    }
                }
                return null
            },
        }
    }
</script>

<style lang="scss" scoped>
.component-container {
    .team-members {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 0;
        margin: 0;

        .placeholder {
            position: relative;
            overflow: hidden;
            height: 640px;
            width: 50%;

            @include breakpoint('tablet') {
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>
